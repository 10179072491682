// This file is used for maintaining helper functions

// 카카오 인앱브라우저 탈출 (안드로이드)
const escapeInAppBrowserAndroid = (currentUrl) => {
  const checkKakaoMatch = window.navigator.userAgent.match("KAKAOTALK");
  if (window.navigator.userAgent.match("KAKAOTALK" | "NAVER" | "Instagram")) {
    location.href =
      "intent://" +
      currentUrl +
      "#Intent;scheme=http;package=com.sec.android.app.sbrowser;end";

    if (checkKakaoMatch) {
      location.href = "kakaotalk://inappbrowser/close";
    }
    return;
  }
};

const isKakaoNaverInstaBrowser = () => {
  return window.navigator.userAgent.match(/KAKAOTALK|NAVER|Instagram/g);
};
// estimates index.js
const firebaseDateToRegularDate = (date) => {
  if (date) {
    const regularDate = date.toDate();
    const year = regularDate.getFullYear();
    const month = regularDate.getMonth() + 1;
    const day = regularDate.getDate();
    const formattedDate = year + "년 " + month + "월 " + day + "일";
    return formattedDate;
  }
};

const firebaseDateToEstimateHistoryFormat = (date) => {
  if (date) {
    const regularDate = date.toDate();
    const year = regularDate.getFullYear();
    const month = regularDate.getMonth() + 1;
    const day = regularDate.getDate();
    const hour = regularDate.getHours();
    const minute = regularDate.getMinutes();
    const formattedDate =
      year +
      "년 " +
      month +
      "월 " +
      day +
      "일 " +
      (hour < 10 ? "0" : "") +
      hour +
      ":" +
      (minute < 10 ? "0" : "") +
      minute;
    return formattedDate;
  }
};

const dateToYearMonthDay = (date) => {
  if (date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedDate = year + "년 " + month + "월 " + day + "일";
    return formattedDate;
  }
};

// pages/pdf.js
const getSessionItem = (item, setItem) => {
  const targetItem = sessionStorage.getItem(item);
  const existStorage = () => {
    if (targetItem !== null) {
      return true;
    }
  };

  if (existStorage()) {
    setItem(sessionStorage.getItem(item));
  }
};

// used to initialize Kakao
const kakaoInit = () => {
  const KAKAO_KEY = process.env.kakaoKey;

  const script = document.createElement("script");
  script.src = "https://t1.kakaocdn.net/kakao_js_sdk/2.0.0/kakao.min.js";
  script.integrity =
    "sha384-PFHeU/4gvSH8kpvhrigAPfZGBDPs372JceJq3jAXce11bVA6rMvGWzvP4fMQuBGL";
  script.crossOrigin = "anonymous";
  document.head.appendChild(script);

  script.onload = () => {
    if (!Kakao.isInitialized()) {
      Kakao.init(KAKAO_KEY);
    }
  };
};

// download URL function

const downloadURL = (url) => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = "blob";
  xhr.onload = (event) => {
    const blob = xhr.response;
  };
  xhr.open("GET", url);
  xhr.send();
};

export {
  getSessionItem,
  kakaoInit,
  firebaseDateToRegularDate,
  firebaseDateToEstimateHistoryFormat,
  dateToYearMonthDay,
  escapeInAppBrowserAndroid,
  isKakaoNaverInstaBrowser,
  downloadURL,
};
