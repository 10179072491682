import Script from "next/script";
import Layout from "../components/Layout";
import { FirebaseProvider } from "../contexts/FirebaseContext";
import "../styles/globals.css";
import { useEffect } from "react";
import { kakaoInit } from "../components/Utils";
import { escapeInAppBrowserAndroid } from "../components/Utils";
import ReactGA from "react-ga4";

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    // if (window.navigator.userAgent.match(/KAKAOTALK/g)) {
    //   escapeInAppBrowserAndroid(
    //     window.location.host + window.location.pathname
    //   );
    // }
    kakaoInit();
    // google analytics 4
    const GAMeasurementID = "G-26G1G9B17K";
    ReactGA.initialize(GAMeasurementID);
    console.log(
      "%c안녕하세요. 오세나를 사용해주셔서 감사합니다.",
      "color: blue"
    );
    return;
  }, []);

  return (
    <>
      <FirebaseProvider>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </FirebaseProvider>
    </>
  );
}

export default MyApp;
