import {
  collection,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db, auth } from "./firebase";

// names of firebase collections
const WHITELIST_COLLECTION = "closedBetaEmails";
const LAUNCH_COLLECTION = "launch";

export const addWhiteListEmail = async (email) => {
  const user = auth.currentUser;
  if (user) {
    await setDoc(doc(db, WHITELIST_COLLECTION, email), {
      email: email,
      senderEmail: user.email,
    });
  }
};

export const addLaunchEmail = async (email) => {
  await setDoc(doc(db, LAUNCH_COLLECTION, email), {
    email: email,
    timeStamp: serverTimestamp(),
  });
};

export const existsWhitelistEmail = async (email) => {
  const emailRef = doc(db, WHITELIST_COLLECTION, email);
  const emailSnap = await getDoc(emailRef);
  if (emailSnap.exists()) {
    return true;
  } else {
    return false;
  }
};

export const deleteWhitelistEmail = async (email) => {
  const user = auth.currentUser;
  if (user) {
    await deleteDoc(doc(db, WHITELIST_COLLECTION, email));
  } else {
    console.log("log in first");
  }
};
