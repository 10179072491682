import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_PlHTHAoPqdx9o-iKZLo01fm_PYph5yg",
  authDomain: "ocena-2c637.firebaseapp.com",
  projectId: "ocena-2c637",
  storageBucket: "ocena-2c637.appspot.com",
  messagingSenderId: "1043102531436",
  appId: "1:1043102531436:web:7a9cd10bc3299e4e7b3d49",
  measurementId: "G-26G1G9B17K",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
const storage = getStorage();

export { app, auth, provider, db, storage };
// const analytics = getAnalytics(app);
