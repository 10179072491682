import { createContext, useEffect, useState } from "react";
import {
  collection,
  setDoc,
  getDoc,
  getDocs,
  doc,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  limitToLast,
  startAfter,
  endBefore,
} from "firebase/firestore";
import { db, auth, provider } from "../firebase/firebase";
import {
  getAdditionalUserInfo,
  signInWithPopup,
  sendEmailVerification,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { toast } from "react-toastify";
import { useRouter } from "next/router";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteWhitelistEmail,
  existsWhitelistEmail,
} from "../firebase/whitelist";

const FirebaseContext = createContext();

const FirebaseProvider = ({ children }) => {
  const router = useRouter();
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [targetEstimate, setTargetEstimate] = useState(null);
  const [targetTemplate, setTargetTemplate] = useState(null);
  const [lastEstimateOnPage, setLastEstimateOnPage] = useState(null);
  const [firstEstimateOnPage, setFirstEstimateOnPage] = useState(null);

  const [salesboardsPerPage, setSalesboardsPerPage] = useState(10);
  const [teamSalesboardsPerPage, setTeamSalesboardsPerPage] = useState(10);

  const SALESBOARD_COLLECTION = "salesboard";

  const salesboardCollection = collection(db, SALESBOARD_COLLECTION);

  const existsNextPage = async (allEstimates, lastVisible) => {
    if (allEstimates.length < salesboardsPerPage) {
      return false;
    } else {
      const nextDoc = [];
      const nextExistQuery = query(
        salesboardCollection,
        where("ownerUid", "==", auth.currentUser.uid),
        orderBy("lastEdited", "desc"),
        startAfter(lastVisible),
        limit(1)
      );
      const querySnapshotNext = await getDocs(nextExistQuery);
      querySnapshotNext.forEach((doc) => {
        nextDoc.push(doc.data());
      });
      if (!nextDoc[0]) {
        return false;
      } else {
        return true;
      }
    }
  };

  const existsPrevPage = async (firstVisible) => {
    const prevDoc = [];
    const prevExistQuery = query(
      salesboardCollection,
      where("ownerUid", "==", auth.currentUser.uid),
      orderBy("lastEdited", "desc"),
      endBefore(firstVisible),
      limitToLast(1)
    );
    const querySnapshotPrev = await getDocs(prevExistQuery);
    querySnapshotPrev.forEach((doc) => {
      prevDoc.push(doc.data());
    });
    if (!prevDoc[0]) {
      return false;
    } else {
      return true;
    }
  };

  const existsSharedNextPage = async (allEstimates, lastVisible, ownerUids) => {
    if (allEstimates.length < teamSalesboardsPerPage) {
      return false;
    } else {
      const nextDoc = [];
      const nextExistQuery = query(
        salesboardCollection,
        where("ownerUid", "in", ownerUids),
        orderBy("lastEdited", "desc"),
        startAfter(lastVisible),
        limit(1)
      );
      const querySnapshotNext = await getDocs(nextExistQuery);
      querySnapshotNext.forEach((doc) => {
        nextDoc.push(doc.data());
      });
      if (!nextDoc[0]) {
        return false;
      } else {
        return true;
      }
    }
  };

  const existsSharedPrevPage = async (firstVisible, ownerUids) => {
    const prevDoc = [];
    const prevExistQuery = query(
      salesboardCollection,
      where("ownerUid", "in", ownerUids),
      orderBy("lastEdited", "desc"),
      endBefore(firstVisible),
      limitToLast(1)
    );
    const querySnapshotPrev = await getDocs(prevExistQuery);
    querySnapshotPrev.forEach((doc) => {
      prevDoc.push(doc.data());
    });
    if (!prevDoc[0]) {
      return false;
    } else {
      return true;
    }
  };

  const getFirstTenSalesboards = async (setHasNextPage, limitNum) => {
    const q = query(
      salesboardCollection,
      where("ownerUid", "==", auth.currentUser.uid),
      orderBy("lastEdited", "desc"),
      // limit(salesboardsPerPage)
      limit(limitNum)
    );
    const querySnapshot = await getDocs(q);
    const allEstimates = [];
    querySnapshot.forEach((doc) => {
      allEstimates.push(doc);
    });

    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    const firstVisible = querySnapshot.docs[0];
    setLastEstimateOnPage(lastVisible);
    setFirstEstimateOnPage(firstVisible);

    const hasNextPage = await existsNextPage(allEstimates, lastVisible);
    setHasNextPage(hasNextPage);

    return allEstimates;
  };

  const getFirstTenSharedSalesboards = async (setHasNextPage, ownerUids) => {
    const q = query(
      salesboardCollection,
      where("ownerUid", "in", ownerUids),
      orderBy("lastEdited", "desc"),
      limit(teamSalesboardsPerPage)
    );
    const querySnapshot = await getDocs(q);
    const allSharedSalesboards = [];
    querySnapshot.forEach((doc) => {
      allSharedSalesboards.push(doc);
    });

    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    const firstVisible = querySnapshot.docs[0];
    setLastEstimateOnPage(lastVisible);
    setFirstEstimateOnPage(firstVisible);

    const hasNextPage = await existsSharedNextPage(
      allSharedSalesboards,
      lastVisible,
      ownerUids
    );
    setHasNextPage(hasNextPage);

    return allSharedSalesboards;
  };

  const getNextTenSalesboards = async (
    setHasNextPage,
    totalSalesboardsPerPage
  ) => {
    try {
      const next = query(
        salesboardCollection,
        where("ownerUid", "==", auth.currentUser.uid),
        orderBy("lastEdited", "desc"),
        startAfter(lastEstimateOnPage),
        limit(totalSalesboardsPerPage)
      );
      const querySnapshot = await getDocs(next);
      const allEstimates = [];
      querySnapshot.forEach((doc) => {
        allEstimates.push(doc);
      });
      if (allEstimates.length > 0) {
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastEstimateOnPage(lastVisible);
        const firstVisible = querySnapshot.docs[0];
        setFirstEstimateOnPage(firstVisible);

        const hasNextPage = await existsNextPage(allEstimates, lastVisible);
        setHasNextPage(hasNextPage);

        return allEstimates;
      } else {
        toast("다음 페이지는 비어있습니다.", { toastId: "NEXT_PAGE_EMPTY" });
      }
    } catch (error) {
      return;
    }
  };

  const getNextTenSharedSalesboards = async (setHasNextPage, ownerUids) => {
    try {
      const next = query(
        salesboardCollection,
        where("ownerUid", "in", ownerUids),
        orderBy("lastEdited", "desc"),
        startAfter(lastEstimateOnPage),
        limit(teamSalesboardsPerPage)
      );
      const querySnapshot = await getDocs(next);
      const allEstimates = [];
      querySnapshot.forEach((doc) => {
        allEstimates.push(doc);
      });
      if (allEstimates.length > 0) {
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastEstimateOnPage(lastVisible);
        const firstVisible = querySnapshot.docs[0];
        setFirstEstimateOnPage(firstVisible);

        const hasNextPage = await existsNextPage(allEstimates, lastVisible);
        setHasNextPage(hasNextPage);

        return allEstimates;
      } else {
        toast("다음 페이지는 비어있습니다.", { toastId: "NEXT_PAGE_EMPTY" });
      }
    } catch (error) {
      return;
    }
  };

  // grab all salesboards that are shared
  const getAllSharedSalesboards = async (ownerUids) => {
    try {
      const next = query(
        salesboardCollection,
        where("ownerUid", "in", ownerUids),
        orderBy("lastEdited", "desc")
      );
      const querySnapshot = await getDocs(next);
      const allEstimates = [];
      querySnapshot.forEach((doc) => {
        allEstimates.push(doc);
      });
      if (allEstimates.length > 0) {
        return allEstimates;
      }
    } catch (error) {
      return;
    }
  };

  const getPreviousTenSalesboards = async (
    setHasPrevPage,
    totalSalesboardsPerPage
  ) => {
    try {
      const prev = query(
        salesboardCollection,
        where("ownerUid", "==", auth.currentUser.uid),
        orderBy("lastEdited", "desc"),
        endBefore(firstEstimateOnPage),
        limitToLast(totalSalesboardsPerPage)
      );
      const querySnapshot = await getDocs(prev);
      const allEstimates = [];
      querySnapshot.forEach((doc) => {
        allEstimates.push(doc);
      });
      if (allEstimates.length > 0) {
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastEstimateOnPage(lastVisible);
        const firstVisible = querySnapshot.docs[0];
        setFirstEstimateOnPage(firstVisible);

        const hasPrevPage = await existsPrevPage(firstVisible);
        setHasPrevPage(hasPrevPage);

        return allEstimates;
      } else {
        toast("이전 페이지는 비어있습니다.", { toastId: "PREV_PAGE_EMPTY" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPreviousTenSharedSalesboards = async (setHasPrevPage, ownerUids) => {
    try {
      const prev = query(
        salesboardCollection,
        where("ownerUid", "in", ownerUids),
        orderBy("lastEdited", "desc"),
        endBefore(firstEstimateOnPage),
        limitToLast(teamSalesboardsPerPage)
      );
      const querySnapshot = await getDocs(prev);
      const allEstimates = [];
      querySnapshot.forEach((doc) => {
        allEstimates.push(doc);
      });
      if (allEstimates.length > 0) {
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastEstimateOnPage(lastVisible);
        const firstVisible = querySnapshot.docs[0];
        setFirstEstimateOnPage(firstVisible);

        const hasPrevPage = await existsSharedPrevPage(firstVisible, ownerUids);
        setHasPrevPage(hasPrevPage);

        return allEstimates;
      } else {
        toast("이전 페이지는 비어있습니다.", { toastId: "PREV_PAGE_EMPTY" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // if user is logged in, load user's estimates
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        if (user.emailVerified && user.displayName) {
          setCurrentUser(user);
        }
      } else {
        setCurrentUser(null);
      }
    });
  }, []);

  // checks if there is a logged in user
  const isLoggedIn = () => {
    const user = auth.currentUser;
    if (user) {
      return true;
    } else {
      return false;
    }
  };

  // sends email verification once user is logged in with unverified email
  const onEmailVerification = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
    } catch (error) {
      toast(error.code, { toastId: "ERROR_MSG_EMAIL_VERIFICATION" });
    }
  };

  // logs user in (used after email is verified)
  const setCurrentUserEmailVerification = () => {
    setCurrentUser(auth.currentUser);
  };

  // resets user password and pushes to login page
  const sendResetPasswordEmailAndSendToLoginPage = async (email) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        router.push("/login");
        toast("비밀번호 리셋 이메일 발송", {
          toastId: "PASSWORD_RESET_EMAIL_SENT",
        });
      })
      .catch((error) => {
        const toastMessage = error.code;
        switch (error.code) {
          case "auth/invalid-email":
            toastMessage = "이메일 주소를 다시 확인해 주세요.";
            break;
        }
        toast(toastMessage, { toastId: "ERROR_MSG_RESET_PASSWORD" });
      });
  };

  // retrieves user data from firestore DB
  const getUserFromDB = async () => {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        return userSnap;
      } else {
        return false;
      }
    }
  };

  // handles user registration to firestore DB
  const registerUserToDB = async (user) => {
    await setDoc(doc(db, "users", user.uid), {
      email: user.email,
      displayName: user.displayName,
    });
  };

  const isNewUser = (userData) => {
    return getAdditionalUserInfo(userData).isNewUser;
  };

  // sign up/in a user with Google
  const signInWithGoogle = async () => {
    setIsLoaded(false);
    try {
      const userData = await signInWithPopup(auth, provider);
      const user = userData.user;
      if (isNewUser(userData)) {
        user.delete().then(() => {
          auth.signOut().then(() => {
            toast("계정이 없습니다.");
            setIsLoaded(true);
          });
        });
        return null;
      } else {
        setCurrentUser(user);
        setIsLoaded(true);
        return userData;
      }
    } catch (error) {
      const toastMessage = error.code;
      toast(toastMessage, { toastId: "ERROR_MSG_EMAIL_LOGIN_GOOGLE" });
    }
  };

  const signUpWithGoogle = async () => {
    setIsLoaded(false);
    try {
      const userData = await signInWithPopup(auth, provider);
      const user = userData.user;

      const userExists = await existsWhitelistEmail(user.email);

      if (isNewUser(userData) && userExists) {
        registerUserToDB(user);
        setCurrentUser(user);
        // await deleteWhitelistEmail(user.email);
        setIsLoaded(true);
        return userData;
      } else if (isNewUser(userData)) {
        // delete if user is not in whitelistEmail
        user.delete().then(() => {
          auth.signOut().then(() => {
            toast("프로덕트 엑세스 문의를 부탁드립니다.");
            setIsLoaded(true);
          });
        });
      }
    } catch (error) {}
  };

  // sign up user with email and password
  // returns signed up user's credential
  const signUpWithEmailAndPassword = async (email, password) => {
    try {
      const userExists = await existsWhitelistEmail(email);
      if (userExists) {
        const userData = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        // await deleteWhitelistEmail(email);
        return userData;
      } else {
        toast("프로덕트 엑세스 문의를 부탁드립니다.", {
          toastId: "WHITELIST_EMAIL_SIGNUP_NONE_MSG",
        });
      }
    } catch (error) {
      const toastMessage = error.code;
      switch (error.code) {
        case "auth/email-already-in-use":
          toastMessage = "이미 계정이 존재합니다.";
          break;
        case "auth/invalid-email":
          toastMessage = "이메일 주소가 맞는지 확인해 주세요.";
          break;
      }
      toast(toastMessage, { toastId: "ERROR_MSG_EMAIL_SIGNUP" });
      // console.log(error.message)
    }
  };

  // sign in user with email and password
  // returns signed in user's credential
  const logInWithEmailAndPassword = async (email, password) => {
    try {
      const userData = await signInWithEmailAndPassword(auth, email, password);
      return userData;
    } catch (error) {
      const toastMessage = error.code;
      switch (error.code) {
        case "auth/wrong-password":
          toastMessage = "패스워드가 틀렸습니다.";
          break;
        case "auth/invalid-email":
          toastMessage = "이메일 주소를 다시 확인해 주세요.";
          break;
        case "auth/user-not-found":
          toastMessage = "아이디가 존재하지 않습니다.";
          break;
      }
      toast(toastMessage, { toastId: "ERROR_MSG_EMAIL_LOGIN" });
    }
  };

  // logs user out
  const logOut = () => signOut(auth).then(reset);

  const reset = () => {
    setCurrentUser(null);
    setIsLoaded(true);
  };

  return (
    <FirebaseContext.Provider
      value={{
        currentUser,
        getUserFromDB,
        getFirstTenSalesboards,
        getFirstTenSharedSalesboards,
        getNextTenSalesboards,
        getNextTenSharedSalesboards,
        getPreviousTenSalesboards,
        getPreviousTenSharedSalesboards,
        targetEstimate,
        setTargetEstimate,
        targetTemplate,
        setTargetTemplate,
        auth,
        signInWithGoogle,
        signUpWithGoogle,
        logInWithEmailAndPassword,
        signUpWithEmailAndPassword,
        onEmailVerification,
        sendResetPasswordEmailAndSendToLoginPage,
        setCurrentUserEmailVerification,
        logOut,
        isLoaded,
        getAllSharedSalesboards,
        setSalesboardsPerPage,
        setTeamSalesboardsPerPage,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseContext, FirebaseProvider };
